import React, { useState, useEffect } from "react";
import threeDots from "../../assets/threeDots.svg";
import upIcon from "../../assets/up.svg";
import downIcon from "../../assets/down.svg";
import dirham from "../../assets/dirham.png";
import { useQuery } from "@tanstack/react-query";
import { getBalance } from "../../services/balance";
import toast from "react-hot-toast";

const TokenQuantity = () => {
  const [token, setToken] = useState(0);
  const [tokenValue, setTokenValue] = useState(0);
  const [address, setAddress] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    const storedAddress = localStorage.getItem("address");
    if (storedAddress) {
      setAddress(storedAddress);
      console.log("Stored address:", storedAddress);
    }
  }, []);

  const { data, isLoading, isError } = useQuery({
    queryKey: ["balance", address],
    queryFn: () => getBalance(address),
    enabled: !!address,
    onError: (error) => {
      toast.error(error.message);
      console.error(error);
    },
  });

  useEffect(() => {
    if (data?.balance) {
      setToken(data.balance);
      console.log("Fetched balance:", data.balance);
    }
  }, [data]);

  useEffect(() => {
    setTotalAmount(tokenValue * token);
  }, [tokenValue, token]);

  const tokenData = [
    {
      title: "Token Quantity",
      value: token,
      percentage: "+20%",
      description: "from last month",
      icon: upIcon,
      color: "#34C759",
    },
    {
      title: "Token Value",
      value: tokenValue,
      currency: "USDT",
      percentage: "+20%",
      description: "from yesterday",
      icon: downIcon,
      color: "#FF3B30",
    },
    {
      title: "Total Amount",
      value: totalAmount,
      currency: "USDT",
      percentage: "+20%",
      description: "from last year",
      icon: upIcon,
      color: "#34C759",
    },
  ];

  return (
    <div className="grid sm:grid-cols-2 xl:grid-cols-3 gap-4">
      {tokenData.map((data, index) => (
        <div key={index} className="p-4 shadow-dao-shadow rounded-[10px]">
          <div className="flex justify-between">
            <p className="text-[#66666E] text-[16px]">{data.title}</p>
          </div>
          <p className="my-6 flex items-center">
            <span className="text-[40px] font-bold leading-[48px]">
              {parseFloat(data.value).toFixed(2)}
            </span>
            <span className="text-[40px] font-semibold leading-[48px] pl-2">
              {data.currency}
            </span>
          </p>
        </div>
      ))}
    </div>
  );
};

export default TokenQuantity;
