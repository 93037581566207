import React, { useEffect, useState } from "react";
import lockingPeriodBg from "../../assets/lockingBg.png";
import { toast } from "react-hot-toast";
import coinBox from "../../assets/coinBox.png";
import lock from "../../assets/lock.svg";
import { ethers } from "ethers";
import tokenLockerAbi from "./TokenLockerAbi";
import { useQueryClient } from "@tanstack/react-query";
import MetaMaskSDK from "@metamask/sdk";
import TokenLockPopup from "./Pop-Up/TokenLockPopup";
import SuccessPopup from "./Pop-Up/TokenlockSuccess";
import FaildPopup from "./Pop-Up/FailedLock";
import { getBalance } from "../../services/balance";
import { useQuery } from "@tanstack/react-query";

const LockingPeriod = () => {
  const queryClient = useQueryClient();
  const [address, setAddress] = useState(
    localStorage.getItem("address") || null
  );
  console.log(address, "from address state");
  const [balance, setBalance] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);
  const [signer, setSigner] = useState(null);
  const [transactionResult, setTransactionResult] = useState("");
  const [lockData, setLockData] = useState({
    tokenQuantity: "",
    months: "",
  });
  const [error, setError] = useState({
    tokenQuantity: "",
    months: "",
  });

  const MMSDK = new MetaMaskSDK({
    useDeeplink: true,
    injectProvider: true,
  });

  const { data, isLoading, isError } = useQuery({
    queryKey: ["balance", address],
    queryFn: () => getBalance(address),
    enabled: !!address,
    onError: (error) => {
      toast.error(error.message);
      console.error(error);
    },
  });

  useEffect(() => {
    if (data?.balance) {
      setBalance(data.balance);
      console.log("Fetched balance:", data.balance);
    }
  }, [data]);

  useEffect(() => {
    const initializeSigner = async () => {
      try {
        let provider;

        // Check if MetaMask is available on browser or mobile
        if (window.ethereum) {
          provider = new ethers.providers.Web3Provider(window.ethereum);
        } else if (MMSDK.getProvider()) {
          provider = new ethers.providers.Web3Provider(MMSDK.getProvider());
        } else {
          console.error("MetaMask not connected or provider unavailable.");
          return;
        }

        if (provider) {
          const accounts = localStorage.getItem("address") || null;
          if (accounts !== null) {
            const connectedSigner = provider.getSigner();
            setSigner(connectedSigner);
            setAddress(accounts);
            console.log("Signer and address initialized successfully!");
          } else {
            console.error("No accounts found. Please connect your wallet.");
            toast.error("Please connect your wallet.");
          }
        }
      } catch (error) {
        console.error("Error initializing signer:", error);
        toast.error("Failed to initialize MetaMask.");
      }
    };

    // Initialize signer only if not already set
    if (!signer) {
      initializeSigner();
    }
  }, [signer]);

  const onChangeHandler = (e) => {
    const { name, value } = e.target;

    if (name === "tokenQuantity") {
      // Updated regex to allow numbers like 0.1 or 0. or any decimal number
      if (value === "" || /^\d+(\.\d*)?$/.test(value)) {
        if (parseFloat(value) > parseFloat(balance)) {
          setError({
            ...error,
            tokenQuantity: "Insufficient balance",
          });
        } else {
          setError({ ...error, tokenQuantity: "" });
        }
        setLockData((prevData) => ({ ...prevData, [name]: value }));
      } else {
        setError({
          ...error,
          tokenQuantity: "Please enter a valid number",
        });
      }
    }

    if (name === "months") {
      setLockData((prevData) => ({ ...prevData, [name]: value }));
      if (value === "") {
        setError({ ...error, months: "Please select a time period" });
      } else {
        setError({ ...error, months: "" });
      }
    }
  };

  const lockTokens = async () => {
    if (!signer) {
      console.error("Signer not available.");
      toast.error("Signer not initialized. Please connect your wallet.");
      return;
    }

    const tokenLockerContractAddress =
      "0xe1fd5229e48910cc0711fbCd1F52756D83d5B1d1";
    const tokenLockerContract = new ethers.Contract(
      tokenLockerContractAddress,
      tokenLockerAbi,
      signer
    );

    const amount = ethers.BigNumber.from(lockData.tokenQuantity * 100);
    const months = ethers.BigNumber.from(lockData.months);

    try {
      console.log("Locking tokens...");

      const tx = await tokenLockerContract.lockTokens(amount, months);
      setIsPopupOpen(true);
      console.log("Transaction sent:", tx);
      const receipt = await tx.wait();
      console.log("Transaction confirmed:", receipt);
      setIsPopupOpen(false);
      setSuccess(true);
      queryClient.invalidateQueries(["balance", address]);
      setTransactionResult("Tokens locked successfully");
      setLockData({
        tokenQuantity: "",
        months: "",
      });
    } catch (error) {
      console.error("Error locking tokens:", error);

      if (error.code === 4001) {
        toast.error("Transaction rejected by user.");
      } else if (error.code === -32000) {
        toast.error("Insufficient funds or invalid gas.");
      } else {
        setFailed(true);
      }

      setTransactionResult("Failed to lock tokens.");
    }
  };

  const dataHandler = async (e) => {
    e.preventDefault();
    let valid = true;
    let newError = { tokenQuantity: "", months: "" };

    // Validate tokenQuantity
    if (!lockData.tokenQuantity.trim()) {
      newError.tokenQuantity = "Token quantity is required";
      valid = false;
    } else if (isNaN(lockData.tokenQuantity)) {
      newError.tokenQuantity = "Please enter a valid number";
      valid = false;
    } else if (parseFloat(lockData.tokenQuantity) > parseFloat(balance)) {
      newError.tokenQuantity = "Insufficient balance";
      valid = false;
    }

    // Validate months
    if (!lockData.months) {
      newError.months = "Please select a time period";
      valid = false;
    }

    setError(newError);

    if (valid) {
      await lockTokens();
    }
  };

  return (
    <>
      <div className="bg-locking-bg w-full h-full bg-cover bg-no-repeat bg-center py-28 flex flex-col gap-28 rounded-[10px]">
        <form
          onSubmit={dataHandler}
          className="flex flex-col gap-6 w-[86%] m-auto"
        >
          <div className="flex flex-col gap-1">
            <label className="text-[14px] font-medium leading-[17px]">
              Token Quantity
            </label>
            <input
              className={`px-2 py-3 rounded-[6px] text-[#000000] placeholder:text-[#9999A1] focus:outline-none focus:ring-2 ${
                error.tokenQuantity
                  ? "focus:ring-red-700 animate-shake"
                  : "focus:ring-[#0E7570]"
              }`}
              type="text"
              onChange={onChangeHandler}
              value={lockData.tokenQuantity}
              name="tokenQuantity"
              placeholder="Enter token quantity"
            />
            {error.tokenQuantity && (
              <p className="text-red-500 text-sm">{error.tokenQuantity}</p>
            )}
          </div>

          <div className="flex flex-col gap-1">
            <label className="text-[14px] font-medium leading-[17px]">
              Time Period
            </label>
            <select
              onChange={onChangeHandler}
              value={lockData.months}
              name="months"
              className={`px-2 py-3 rounded-[6px] text-[#000000] placeholder:text-[#9999A1] focus:outline-none focus:ring-2 ${
                error.months
                  ? "focus:ring-red-700 animate-shake"
                  : "focus:ring-[#0E7570]"
              }`}
            >
              <option value="" disabled>
                Select time period
              </option>
              <option value="3">3 Months</option>
              <option value="6">6 Months</option>
              <option value="9">9 Months</option>
              <option value="12">12 Months</option>
            </select>
            {error.months && (
              <p className="text-red-500 text-sm">{error.months}</p>
            )}
          </div>

          <div className="flex justify-center">
            <button
              type="submit"
              className="bg-[#0E7570] text-white w-full justify-center flex gap-2 px-2 py-3 rounded-[6px]"
              disabled={!signer}
            >
              <div className="flex gap-1">
                Lock Now <img src={lock} alt="lock" />
              </div>
            </button>
          </div>
        </form>

        <div className="w-full flex justify-center">
          <img className="max-w-full" src={coinBox} alt="coinBox" />
        </div>
      </div>
      {isPopupOpen && (
        <TokenLockPopup
          isOpen={isPopupOpen}
          onClose={() => setIsPopupOpen(false)}
        />
      )}
      {success && (
        <SuccessPopup
          message="Token Locked Successfully"
          isOpen={success}
          onClose={() => setSuccess(false)}
        />
      )}
      {failed && (
        <FaildPopup isOpen={failed} onClose={() => setFailed(false)} />
      )}
    </>
  );
};

export default LockingPeriod;
