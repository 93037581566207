import React from "react";
import HeroSection from "./HeroSection";
import MainLayout from "../../components/MainLayout";
import TokenEconomics from "./TokenEconomics";
import EstimateRewards from "./EstimateRewards";
import IncomeStoring from "./IncomeStoring";
import P2PService from "./P2PService";
import CuffiPortfolio from "./CuffiPortfolio";
import ProportionalRewards from "./ProportionalRewards";
import FrequentlyAskQuestions from "./FrequentlyAskQuestions";
import TokenLockerApp from "../../components/clientDashboard/TokenLock";

const Index = () => {
  return (
    <MainLayout>
      <HeroSection />
      <TokenEconomics />
      <CuffiPortfolio />
      <EstimateRewards />
      <IncomeStoring />
      {/* <P2PService /> */}
      <ProportionalRewards />
      <FrequentlyAskQuestions />
      {/* <TokenLockerApp /> */}
    </MainLayout>
  );
};

export default Index;
