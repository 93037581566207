import React, { useState } from "react";
import BarChart2 from "../BarChart";

const ProfitDistribution = () => {
  const data2 = [
    { name: "Revenue", value: 3000000 },
    { name: " Profit", value: 2000000 },
    { name: " Distribution", value: 1000000 },
  ];

  const data3 = [
    { name: "Revenue", value: 7000000 },
    { name: "Profit", value: 3000000 },
    { name: "Distribution", value: 1500000 },
  ];

  const data4 = [
    { name: "Revenue", value: 4000000 },
    { name: "Profit", value: 2500000 },
    { name: "Distribution", value: 1200000 },
  ];
  const [chartData, setChartData] = useState(data2);
  function updateChartData(data) {
    setChartData(data);
  }
  return (
    <>
      <div className="grid grid-cols-1  lg:grid-cols-[30%_70%]  justify-items-center shadow-custom py-4 lg:py-8 md:px-4 lg:px-8 rounded-[10px] border-[1px]">
        <p className="lg:col-span-2 grid justify-self-start text-[#0E7570] text-[20px] font-semibold leading-[24px] pl-4 sm:pl-2">
          Profit Distribution
        </p>
        <div className="flex lg:flex-col gap-2 sm:gap-6 mt-10 justify-center">
          <button
            className="shadow-custom-button rounded-[14px]  text-[14px] sm:text-[18px] font-medium px-4 py-2"
            onClick={() => updateChartData(data2)}
          >
            FY24-Q1
          </button>
          <button
            className="shadow-custom-button rounded-[14px]  text-[14px] sm:text-[18px] font-medium px-4 py-2"
            onClick={() => updateChartData(data3)}
          >
            FY24-Q2
          </button>
          <button
            className="shadow-custom-button rounded-[14px]  text-[14px] sm:text-[18px] font-medium px-4 py-2"
            onClick={() => updateChartData(data4)}
          >
            FY24-Q3
          </button>
        </div>
        <div className="mt-10  flex justify-center items-center ">
          <BarChart2 data2={chartData} />
        </div>
      </div>
    </>
  );
};

export default ProfitDistribution;
