import React from "react";
import emailBox from "../../assets/emailBox.svg";
import location from "../../assets/location.svg";

const GetInTouch = () => {
  const handleEmailClick = (e) => {
    e.preventDefault();
    window.location.href = "mailto:cuffi.shop@gmail.com";
  };
  return (
    <div className="w-[90%] mx-auto">
      <div className="grid grid-cols-12">
        {/* Map Section */}
        <div className="col-span-12 md:col-span-7 xl:col-span-8 h-[350px] md:h-[450px] order-2 md:order-1">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3620.0455790532914!2d55.3025525!3d25.0653016!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6f94eb17f571%3A0xd28793fe0187c0ca!2sCuppaa%20Coffee!5e0!3m2!1sen!2sae!4v1694457620654!5m2!1sen!2sae"
            width="100%"
            height="100%"
            allowFullScreen=""
            loading="lazy"
            className="w-full h-full object-cover rounded-b-[10px] md:rounded-b-[0px] md:rounded-bl-[10px] md:rounded-l-[10px]"
            title="Cuppaa Coffee Location"
          ></iframe>
        </div>

        {/* Contact Info Section */}
        <div className="bg-white shadow-dao-shadow rounded-t-[10px] md:rounded-t-[0px] md:rounded-tr-[10px] md:rounded-r-[10px] col-span-12 md:col-span-5 xl:col-span-4 flex flex-col gap-6 pb-10 sm:pb-20  md:pl-[40px] lg:pl-[100px] pt-[35px] md:pt-[100px] order-1 md:order-2">
          <h2 className="text-[#0E7570] text-center md:text-left font-bold text-3xl leading-tight">
            Get In Touch
          </h2>

          <div className="flex justify-center sm:pl-[70px] md:pl-0 sm:justify-start gap-3 mt-4">
            <img src={emailBox} alt="Email Icon" className="w-6 h-6" />
            <a
              href="#"
              onClick={handleEmailClick}
              title="Click to Email Cuffi Support"
              className="text-[#0A0A0A] font-medium text-lg"
            >
              cuffi.shop@gmail.com
            </a>
          </div>

          <div className="flex justify-center sm:pl-[70px] md:pl-0 sm:justify-start gap-3 ">
            <img src={location} alt="Location Icon" className="w-6 h-6" />

            <div className="text-[#0A0A0A] font-medium text-lg">
              <p>Shop 280,</p>
              <p>Dubai</p>
              <p>United Arab Emirates</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;
