import React from "react";

const Reports = () => {
  return (
    <>
      <div className="w-full h-screen flex justify-center items-center text-[30px] font-medium">
        Coming soon...
      </div>
    </>
  );
};

export default Reports;
