import React from "react";
import MainLayout from "../../components/MainLayout";

const ProductIndex = () => {
  return (
    <MainLayout>
      <div>
        <div className="h-[50vh] w-full text-center text-black">Product</div>
      </div>
    </MainLayout>
  );
};

export default ProductIndex;
