import React from "react";
const TokenLockPopup = ({ isOpen, onClose }) => {
  return (
    <>
      <div
        className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-20 z-50"
        // onClick={onClose}
      >
        <div
          className="bg-white p-6 rounded-lg shadow-lg w-[300px] relative"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex flex-col items-center gap-4 justify-center">
            <p className="text-[18px] font-semibold">
              Cuffi token is getting locked
            </p>
            <p>Please wait</p>
            <div className="w-16 h-16 border-4 border-t-4 border-gray-200 rounded-full animate-spin border-t-[#0E7570]"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TokenLockPopup;
