import React, { useEffect, useState } from "react";
import crossIcon from "../../assets/cross.svg";
import metamaskIcon from "../../assets/metamask-icon.svg";
import { Link } from "react-router-dom";

const MetamaskAlert = ({ alert, setAlert }) => {
  return (
    <div>
      {alert && (
        <div
          className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-20 z-50"
          onClick={() => {
            setAlert(false);
          }}
        >
          <div
            className="bg-white p-6 rounded-lg shadow-lg w-[300px] relative"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              className="absolute top-2 right-2 text-gray-500 mt-2 mr-2"
              onClick={() => setAlert(false)}
            >
              <img src={crossIcon} />
            </button>
            <div className="flex flex-col items-center gap-4 justify-center py-12">
              <div className="w-[30%]">
                <img
                  className="animate-left-right"
                  src={metamaskIcon}
                  alt="metaMask"
                />
              </div>
              <p className="text-[18px] font-semibold">MetaMask not found</p>
              <div className="text-[#0E7570] font-semibold px-4 py-2 rounded-[10px]">
                <Link
                  to="https://metamask.io/download/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Click to Proceed...
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MetamaskAlert;
