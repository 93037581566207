import axios from "axios";

export const getBalance = async (address) => {
  try {
    const response = await axios.post("https://backend.cuffi.shop/getBalance", {
      address: address,
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      throw new Error(error.response.data.message);
    } else {
      throw new Error(error.message);
    }
  }
};
