import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import InvestmentIcon from "./svg/Investment";

const NavItem = ({
  link,
  name,
  activeNavName,
  setActiveNavName,
  iconWhite,
  iconBlack,
  title,
  toggle,
  setToggle,
}) => {
  return (
    <>
      <Link
        to={link}
        className={`${
          name === activeNavName
            ? "font-semibold  text-[#FFFFFF] bg-[#0E7570]   rounded-[10px]"
            : "font-semibold text-[#66666E] "
        } flex items-center  gap-2 sm:gap-x-4 pl-3 sm:pl-6 pr-12 sm:pr-20 py-2 text-lg h-12`}
        onClick={() => {
          setToggle(!toggle);
        }}
      >
        {name === activeNavName ? iconWhite : iconBlack}

        <span>{title}</span>
      </Link>
    </>
  );
};

export default NavItem;
