import React, { useEffect, useState, useRef } from "react";
import MetaMaskSDK from "@metamask/sdk";
import crossIcon from "../assets/cross.svg";
import { useNavigate } from "react-router-dom";
import metamaskIcon from "../assets/metamask-icon.svg";
import MetamaskAlert from "./clientDashboard/MetamaskAlert";
import { ethers } from "ethers";
import Loader from "./Loader";

const PopUp = ({ open, setOpen }) => {
  const [alert, setAlert] = useState(false);
  const [loader, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [defaultAccount, setDefaultAccount] = useState(null);
  const [userBalance, setUserBalance] = useState(null);
  const [ethereum, setEthereum] = useState(null);
  const navigate = useNavigate();
  console.log(userBalance);

  useEffect(() => {
    try {
      const MMSDK = new MetaMaskSDK({
        useDeeplink: true,
        injectProvider: true,
      });
      const provider = MMSDK.getProvider();
      if (provider) {
        setEthereum(provider);
      } else {
        console.error("MetaMask provider not found.");
      }
    } catch (error) {
      console.error("Error initializing MetaMaskSDK:", error);
    }
  }, []);

  const togglePopup = () => {
    setOpen(!open);
  };
  const connectWallet = async () => {
    if (typeof window != "undefined" && typeof window.ethereum != "undefined") {
      try {
        const result = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        accountChangedHandler(result[0]);
      } catch (error) {
        console.error("Error connecting to MetaMask:", error);
      }
    } else {
      setAlert(true);
      console.log("Please install MetaMask");
    }
  };

  const accountChangedHandler = (newAccount) => {
    setDefaultAccount(newAccount);
    localStorage.setItem("address", newAccount);
    setOpen(false);
    navigate("/dashboard");
    getUserBalance(newAccount.toString());
  };

  const getUserBalance = async (address) => {
    try {
      const balance = await window.ethereum.request({
        method: "eth_getBalance",
        params: [address, "latest"],
      });
      const formattedBalance = ethers.utils.formatEther(balance);
      setUserBalance(formattedBalance);
    } catch (error) {
      console.error("Error getting balance:", error);
    }
  };

  const chainChangedHandler = () => {
    window.location.reload();
  };

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", accountChangedHandler);
      window.ethereum.on("chainChanged", chainChangedHandler);

      return () => {
        window.ethereum.removeListener(
          "accountsChanged",
          accountChangedHandler
        );
        window.ethereum.removeListener("chainChanged", chainChangedHandler);
      };
    }
  }, []);

  // useEffect(() => {
  //   if (userBalance != null && defaultAccount) {
  //     setOpen(false);
  //     navigate("/dashboard");
  //   }
  // }, [userBalance, defaultAccount]);

  return (
    <div>
      {open && (
        <div
          className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-20 z-50"
          onClick={togglePopup}
        >
          <div
            className="bg-white p-6 rounded-lg shadow-lg w-[300px] relative"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              className="absolute top-2 right-2 text-gray-500 mt-2 mr-2"
              onClick={togglePopup}
            >
              <img src={crossIcon} />
            </button>
            <div className="flex flex-col items-center gap-6 justify-center py-12">
              <div className="w-[30%]">
                <img src={metamaskIcon} alt="metaMask" />
              </div>

              <button
                onClick={connectWallet}
                className="bg-[#0E7570]  hover:bg-[#004442] text-white text-[18px] px-5 py-2 border rounded-[10px]"
              >
                Connect to MetaMask
              </button>
            </div>
          </div>
        </div>
      )}
      <div>
        <MetamaskAlert alert={alert} setAlert={setAlert} />
      </div>
      <div>{loader && <Loader />}</div>
    </div>
  );
};

export default PopUp;
