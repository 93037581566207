import React from "react";
import MainLayout from "../../components/MainLayout";
import HeroSection from "./HeroSection";
import OurGoal from "./OurGoal";
import Vision from "./Vision";
import OurTeam from "./OurTeam";
import FrequentlyAskQuestions from "../home/FrequentlyAskQuestions";
import GetInTouch from "./GetInTouch";

const AboutUs = () => {
  return (
    <MainLayout>
      <div className="bg-white flex flex-col gap-14 md:gap-20">
        <div>
          <HeroSection />
        </div>
        <div>
          <OurGoal />
        </div>
        <div>
          <Vision />
        </div>
        <div>
          <GetInTouch />
        </div>
        <div>
          <FrequentlyAskQuestions />
        </div>
      </div>
    </MainLayout>
  );
};

export default AboutUs;
