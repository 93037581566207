import React, { useState } from "react";

const EstimateRewards = () => {
  const [stackSize, setStackSize] = useState(1000000);
  const [reward, setReward] = useState(10000);

  const handleInputChange = (e) => {
    const value = e.target.value;

    if (!isNaN(value) && value >= 0) {
      const newStackSize = Number(value);
      setStackSize(newStackSize);
      setReward(newStackSize * 0.1);
    } else {
      console.log("Please enter a valid number");
    }
  };

  return (
    <>
      <div className="bg-white px-4 sm:px-0">
        <div className="bg-[#0E75701A] py-12  sm:px-10 lg:px-20  sm:w-[70%] lg:w-[80%] m-auto rounded-[20px]">
          <h2 className="text-[#0E7570] font-bold  text-[20px] md:text-[32px] lg:leading-[38.73px] text-center">
            Estimate Rewards
          </h2>
          <div className="w-full pl-6 lg:pl-0 grid lg:grid-cols-[55%_45%]  gap-y-6  mt-12 lg:place-items-center">
            <div className="w-full lg:border-r-[2px] lg:border-[#FFFFFF] grid place-self-end pl-[5%]">
              <div className="flex flex-col gap-4 w-full">
                <span className="text-[18px] font-medium leading-[21.78px]">
                  Profit
                </span>
                <form className="bg-[#FFFFFF] w-[90%] sm:w-[70%]  h-[60px] rounded-[10px] flex items-center">
                  <input
                    type="text"
                    value={stackSize}
                    onChange={handleInputChange}
                    className="w-full h-full px-4 outline-none rounded-[10px]"
                  />
                </form>
                <p className="text-[16px] w-[70%] font-normal leading-[19.36px] text-[#66666E]">
                  Rewards will be fluctuated depending on profits, participants
                  and revenue pool.
                </p>
              </div>
            </div>
            <div className=" flex flex-col gap-2 ml-[8%]">
              <p className="text-[18px] font-medium ">Earn upto</p>
              <div className="flex items-baseline">
                <span className="text-[#0E7570] font-bold  text-[20px]  md:text-[38px]  lg:text-[50px] xl:text-[64px] whitespace-normal break-all">
                  {reward.toLocaleString()}
                </span>
                <span className="text-[#0E7570] text-[12px] md:text-[18px] lg:text-[24px] font-semibold">
                  Cuffi
                </span>
              </div>
              <p className="text-[#0A0A0A] text-[14px]  md:text-[16px]  xl:text-[20px] font-medium ">
                by the end of locking period
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EstimateRewards;
