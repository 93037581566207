import React from "react";
import image from "../../assets/AboutUs.png";

const HeroSection = () => {
  return (
    <>
      <div className="w-full h-full flex items-center justify-center pt-20">
        <h2 className="text-[32px] text-[#0E7570] font-bold lg:mt-4">
          About Us
        </h2>
        {/* <div className="relative  bg-about-usBg bg-no-repeat bg-center bg-cover h-[300px] md:h-[450px] lg:h-[600px] w-full overflow-hidden"></div> */}
      </div>
    </>
  );
};

export default HeroSection;
