import React from "react";
import { Link, useNavigate } from "react-router-dom";

const WhatsHappening = () => {
  const navigate = useNavigate();
  function handleNavigate() {
    localStorage.setItem("activeNav", "dao");
    navigate("/dashboard/dao");
    window.location.reload();
  }
  return (
    <>
      <div className="bg-whats-happening bg-center bg-no-repeat bg-cover w-full rounded-[10px] grid md:grid-cols-[60%_40%] gap-y-4 pt-6 pb-10 pl-4 pr-6">
        <div>
          <h2 className="text-[20px] font-semibold leading-[24px] text-[#FFFFFF]">
            What's happening?!!
          </h2>
          <p className="text-[18px] mt-8 font-normal leading-[24px] text-[#FFFFFF]">
            Join us at an exclusive token launch party and don't forget to grab
            some freshly baked cookies!
          </p>
        </div>
        <div className="flex items-end justify-center">
          <Link
            to="/dashboard/dao"
            className="bg-white hover:bg-[#f1f0f0] rounded-[10px] text-[#0E7570] font-semibold py-2 px-3"
          >
            View More
          </Link>
        </div>
      </div>
    </>
  );
};

export default WhatsHappening;
