import React from "react";

const InvestmentIcon = ({ fill = "#66666E", ...props }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_258_842)">
      <path
        d="M20.5 23.447H3.5C1.57 23.447 0 21.877 0 19.947V9.94699C0 8.01699 1.57 6.44699 3.5 6.44699H20.5C22.43 6.44699 24 8.01699 24 9.94699V19.947C24 21.877 22.43 23.447 20.5 23.447ZM3.5 7.44699C2.8372 7.44778 2.20178 7.71143 1.73311 8.1801C1.26444 8.64877 1.00079 9.28419 1 9.94699V19.947C1 21.325 2.121 22.447 3.5 22.447H20.5C21.879 22.447 23 21.325 23 19.947V9.94699C23 8.56899 21.879 7.44699 20.5 7.44699H3.5Z"
        fill={fill}
      />
      <path
        d="M19.5 16.447C18.673 16.447 18 15.774 18 14.947C18 14.12 18.673 13.447 19.5 13.447C20.327 13.447 21 14.12 21 14.947C21 15.774 20.327 16.447 19.5 16.447ZM19.5 14.447C19.4342 14.4471 19.3691 14.4602 19.3084 14.4855C19.2476 14.5108 19.1925 14.5478 19.146 14.5944C19.0996 14.6411 19.0628 14.6964 19.0378 14.7572C19.0127 14.818 18.9999 14.8832 19 14.949C19.0001 15.0148 19.0132 15.0799 19.0385 15.1406C19.0638 15.2014 19.1008 15.2565 19.1474 15.303C19.1941 15.3494 19.2494 15.3862 19.3102 15.4112C19.371 15.4363 19.4362 15.4491 19.502 15.449C19.6349 15.4487 19.7622 15.3957 19.856 15.3015C19.9497 15.2074 20.0023 15.0799 20.002 14.947C20.0017 14.8141 19.9487 14.6868 19.8546 14.593C19.7604 14.4993 19.6329 14.4467 19.5 14.447Z"
        fill={fill}
      />
      <path
        d="M23.5003 18.447H19.5003C17.5703 18.447 16.0003 16.877 16.0003 14.947C16.0003 13.017 17.5703 11.447 19.5003 11.447H23.5003C23.6329 11.447 23.7601 11.4997 23.8538 11.5934C23.9476 11.6872 24.0003 11.8144 24.0003 11.947V17.947C24.0003 18.0796 23.9476 18.2068 23.8538 18.3005C23.7601 18.3943 23.6329 18.447 23.5003 18.447ZM19.5003 12.447C18.1213 12.447 17.0003 13.569 17.0003 14.947C17.0003 16.325 18.1213 17.447 19.5003 17.447H23.0003V12.447H19.5003ZM20.0003 7.44699C19.9075 7.44698 19.8166 7.42117 19.7376 7.37242C19.6587 7.32368 19.5949 7.25393 19.5533 7.17099L17.4363 2.93699C17.2877 2.637 17.0799 2.37023 16.8253 2.15281C16.5708 1.93539 16.2748 1.77182 15.9553 1.67199C15.6365 1.56983 15.3003 1.53338 14.967 1.56483C14.6338 1.59628 14.3103 1.69499 14.0163 1.85499L3.73729 7.38699C3.62104 7.44512 3.48672 7.45569 3.3628 7.41647C3.23888 7.37725 3.13511 7.29132 3.07347 7.17689C3.01183 7.06246 2.99718 6.92852 3.03261 6.80347C3.06805 6.67841 3.15079 6.57207 3.26329 6.50699L13.5403 0.972987C13.9525 0.749855 14.4056 0.612256 14.8723 0.568439C15.339 0.524621 15.8097 0.575487 16.2563 0.717987C17.1663 1.00599 17.9033 1.63499 18.3303 2.48899L20.4473 6.72299C20.4855 6.79922 20.5036 6.88395 20.4998 6.96913C20.496 7.05431 20.4705 7.13711 20.4257 7.20966C20.3809 7.28221 20.3183 7.3421 20.2439 7.38364C20.1694 7.42518 20.0856 7.44698 20.0003 7.44699Z"
        fill={fill}
      />
      <path
        d="M10.571 7.447C10.4597 7.44689 10.3517 7.40968 10.264 7.34126C10.1763 7.27284 10.1139 7.17711 10.0867 7.06924C10.0595 6.96136 10.069 6.84749 10.1138 6.74566C10.1586 6.64382 10.2361 6.55984 10.334 6.507L17.763 2.507C17.8208 2.47581 17.8843 2.45632 17.9496 2.44964C18.015 2.44296 18.0811 2.44923 18.144 2.46808C18.207 2.48693 18.2656 2.518 18.3166 2.5595C18.3675 2.60101 18.4098 2.65215 18.441 2.71C18.4722 2.76785 18.4917 2.83127 18.4984 2.89665C18.505 2.96203 18.4988 3.02808 18.4799 3.09104C18.4611 3.154 18.43 3.21263 18.3885 3.26358C18.347 3.31452 18.296 3.35703 18.238 3.388L10.809 7.388C10.7266 7.43125 10.6339 7.4474 10.541 7.43399L10.571 7.447Z"
        fill={fill}
      />
      <defs>
        <clipPath id="clip0_258_842">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </g>
  </svg>
);

export default InvestmentIcon;
