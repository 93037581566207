import React from "react";
import news1 from "../../assets/news1.png";
import news2 from "../../assets/news2.png";
import news3 from "../../assets/news3.png";
import news4 from "../../assets/news4.png";

const LatestNews = () => {
  const newsData = [
    {
      image: news1,
      description: "Upcoming new branches at prime locations",
    },
    {
      image: news2,
      description: "Cuffi token hits its ATH value",
    },
    {
      image: news3,
      description:
        "Latest DAO boosts CUFFI utility with staking and governance upgrades",
    },
    // {
    //   image: news4,
    //   description: "Cuffi token hits its ATH value",
    // },
  ];

  return (
    <div className="w-full shadow-custom rounded-[10px] p-4 border-[1px] h-[100%] ">
      <p className="text-[#0E7570] text-[20px] font-semibold leading-[24.2px]">
        Latest news
      </p>
      <div className="grid sm:grid-cols-2 lg:grid-cols-1 w-full gap-4 mt-8  overflow-auto no-scrollbar">
        {newsData.map((news, index) => (
          <React.Fragment key={index}>
            <div className="">
              <div className="h-full">
                <p className="text-[14px] font-medium text-center md:text-left ">
                  {news.description}
                </p>
                <div className="mt-1 flex justify-center">
                  <img
                    className="h-full"
                    src={news.image}
                    alt={`news${index + 1}`}
                  />
                </div>
              </div>
            </div>
            {index < newsData.length - 1 && <hr className="hidden lg:block" />}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default LatestNews;
