import React from "react";
import incomeStoring from "../../assets/incomeStoring.png";

const IncomeStoring = () => {
  const benefits = [
    {
      title: "Quarterly earnings",
      description: "- up to 5% per annum",
      titleClass: "font-semibold text-[#0A0A0A]",
      descriptionClass: "font-normal text-[#66666E]",
    },
    {
      title: "Extended your locking period",
      description: "to stay invested and continue earning rewards",
      titleClass: "font-semibold text-[#0A0A0A]",
      descriptionClass: "font-normal text-[#66666E]",
    },
    // {
    //   title: "Partial withdrawal",
    //   description: "and early closure options",
    //   titleClass: "font-normal text-[#66666E]",
    //   descriptionClass: "font-semibold text-[#0A0A0A]",
    // },
    // {
    //   title: "Safe storage",
    //   description: "in a saving account",
    //   titleClass: "font-semibold text-[#0A0A0A]",
    //   descriptionClass: "font-normal text-[#66666E]",
    // },
  ];

  return (
    <>
      <div className="w-full bg-white py-20">
        <div className="w-[90%] m-auto grid lg:grid-cols-12 gap-y-8 lg:gap-y-0 lg:gap-x-10">
          <div className="col-span-12 lg:col-span-5 flex items-end w-full">
            <div className="w-[100%] flex justify-center">
              <img
                className=" object-contain"
                src={incomeStoring}
                alt="incomeStoring"
              />
            </div>
          </div>

          <div className="col-span-12 lg:col-span-7 flex justify-center items-center">
            <div className="grid sm:grid-cols-2  gap-6">
              <h2 className="sm:col-span-2 font-bold text-[20px] lg:text-[32px] leading-[30px] lg:leading-[46px] text-[#0E7570] pb-4 text-center lg:text-start">
                Multiply your income by staking
              </h2>

              {benefits.map((benefit, index) => (
                <div
                  key={index}
                  className=" px-5 py-8 md:px-6 shadow-custom border rounded-[10px]"
                >
                  <span
                    className={`${benefit.titleClass} text-[15px] sm:text-[18px] sm:leading-[28px]`}
                  >
                    {benefit.title}
                  </span>
                  <span
                    className={`pl-1 ${benefit.descriptionClass} text-[15px] sm:text-[18px] sm:leading-[28px]`}
                  >
                    {benefit.description}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IncomeStoring;
