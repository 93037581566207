import React, { useEffect, useState } from "react";

const TokenLocked = () => {
  const tokenLockedValues = ["0"];
  const rewardedUsersValues = ["0"];
  const supplyValues = [
    "10M",
    "15M",
    "20M",
    "30M",
    "40M",
    "50M",
    "70M",
    "80M",
    "100M",
  ];

  const [currentTokenLocked, setCurrentTokenLocked] = useState(
    tokenLockedValues[0]
  );
  const [currentRewardedUsers, setCurrentRewardedUsers] = useState(
    rewardedUsersValues[0]
  );
  const [currentSupply, setCurrentSupply] = useState(supplyValues[0]);

  useEffect(() => {
    const updateValues = (valuesArray, setValue) => {
      valuesArray.forEach((value, index) => {
        setTimeout(() => {
          setValue(value);
        }, index * 300);
      });
    };

    updateValues(tokenLockedValues, setCurrentTokenLocked);
    updateValues(rewardedUsersValues, setCurrentRewardedUsers);
    updateValues(supplyValues, setCurrentSupply);
  }, []);

  return (
    <div className="bg-[#004442] w-[100%] py-8 relative">
      <div className="h-[110px] w-full flex gap-4 md:gap-20 lg:gap-32 justify-center items-center">
        <div className="flex flex-col gap-1">
          <span className="text-[20px] sm:text-[24px] md:text-[40px] leading-[20px] md:leading-[48.41px] text-[#FFFFFF] text-center font-bold">
            {currentTokenLocked}
          </span>
          <p className="text-[12px] sm:text-[16px] text-[#FFFFFF] text-center leading-[19.36px] font-medium">
            Token Locked
          </p>
        </div>
        <div className="flex flex-col gap-1">
          <span className="text-[20px] sm:text-[24px] md:text-[40px] leading-[20px] md:leading-[48.41px] text-[#FFFFFF] font-bold text-center">
            {currentRewardedUsers}
          </span>
          <span className="text-[12px] sm:text-[16px] text-[#FFFFFF] leading-[19.36px] font-medium">
            Rewarded Users
          </span>
        </div>
        <div className="flex flex-col gap-1">
          <span className="text-[20px] sm:text-[24px] md:text-[40px] leading-[20px] md:leading-[48.41px] text-[#FFFFFF] font-bold text-center">
            {currentSupply}
          </span>
          <span className="text-[12px] sm:text-[16px] text-[#FFFFFF] leading-[19.36px] font-medium">
            Initial Circulatory Supply
          </span>
        </div>
      </div>
    </div>
  );
};

export default TokenLocked;
