import React, { useEffect, useState } from "react";

const SuccessPopup = ({ message, isOpen, onClose }) => {
  useEffect(() => {
    let timer;
    if (isOpen) {
      timer = setTimeout(() => {
        onClose();
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [isOpen, onClose]);

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-20 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-[300px] text-center">
            <p className="text-green-600 text-lg font-semibold">{message}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default SuccessPopup;
